import React, { Component, useEffect, useState, useRef } from "react";
import Layout from "../../layout";
import GET_IN_TOUCH from "../../get_in_touch/index";
import "flatpickr/dist/themes/material_green.css";
import { useForm, Controller } from "react-hook-form";
import * as axios from "axios";
import Api from "../../../../package.json";
import "../tools/css/css-loader.css";
import moment from "moment";
import downarrow from "../../../assets/down-arrow.png";
import {
  Accordion,
  Card,
  Button,
  ButtonGroup,
  Modal,
  Dropdown,
} from "react-bootstrap";
import track_shipment_lang from "../../lang/track_shipment";
import "./track_shipments.css";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import swal from "sweetalert";
import big_arrow from "../../../../src/assets/toggle-arrow-big.webp";

const qs = require("qs");
var jwt = require("jsonwebtoken");
const Track_shipments = (props) => {
  const [country_code, setCountryCode] = useState(window.country_code);
  const [trackShipmentRes, settrackShipmentRes] = useState([]);
  const [loaderFlag, setloaderFlag] = useState("is-inactive");
  const [pdfbuttonstatus, setpdfbuttonstatus] = useState(false);
  const [outputflag, setoutputflag] = useState(false);
  const [enquire_common_button, setEnquireCommonBUtton] = useState('Submit');


  const [trackShipmentflag, settrackShipmentflag] = useState(false);
  const submitForm = useRef();
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);
  const [mtabs, setMtabs] = useState([]);
  const blValidation = /^[A-Z]{7}[0-9]{5,10}$/i;
  const continerValidation = /^[A-Z]{4}[0-9]{7}$/i;
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [token, setToken] = useState(null);
  const contentRefs = useRef([]);
  const [visibility, setVisibility] = useState([false, false, false]);
  const captchaRef = useRef(null);
  const [seletedTags, setSeletedTags] = useState([]);
  const [LoadedBlNumber, setLoadedBlNumber] = useState([]);
  const [isRotated, setIsRotated] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [selectedDataRes, setselectedDataRes] = useState(0);
  const [tabAccordianCurrentIndex, setTabAccordianCurrentIndex] = useState(0);
  // Function to handle the input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // Function to handle key press (space or comma)
  const handleClick = () => {
    SetTagArray();
  };

  const handleKeyDown = (event) => {
    if (
      event.key === " " ||
      event.key === "," ||
      event.key === "Enter" ||
      event.key === "Tab"
    ) {
      event.preventDefault(); // Prevent default behavior
      SetTagArray();
      // Split input on space, comma, or enter and remove empty strings
      // Clear the input field
    }
  };

  useEffect(() => {
      var id = props.match.params.containerid;
      if(id != null)
      {
       setTags([id.trim()]);
       setMtabs([id.trim()]);
       get_track_shipment_submit(id.trim());
       
      }
    
    }, []);
  const SetTagArray = () => {
    console.log("dfg");
    const newTags = inputValue
      .split(/[\s,]+/) // Split by space, comma, or multiple spaces
      .filter((tag) => tag.trim() !== ""); // Remove empty entries

    let validTags = [];
    let invalidTags = [];
    let existingTags = [];

    newTags.forEach((tag) => {
console.log( validTags.length+'test leng');
setError("");
      if (
        (blValidation.test(tag.trim()) ||
        continerValidation.test(tag.trim())) && validTags.length < 10
      && tags.length < 10) {
        if (!tags.includes(tag.trim())) {
          validTags.push(tag.trim());
        } else {
          existingTags.push(tag.trim());
        }
      } else {
  if(validTags.length < 10
    || tags.length < 10)
    {
      if(validTags.length == 0 && tags.length == 0)
      {
        setError(
          `Please input at least one valid BL or container number.`
        );
      }else{
        setError(
          `Input of more than 10 shipments is not allowed.`
        );
      }
      
    }else{
      invalidTags.push(tag.trim());
    }
       
      }
    });

    if (invalidTags.length > 0) {
      setError(`Invalid B/L or Continer Number: ${invalidTags.join(", ")}.`);
    } else if (existingTags.length > 0) {
      setError(
        `Already existing B/L or Continer Number: ${existingTags.join(", ")}`
      );
    }  else if (validTags.length > 10  && tags.length > 10) {
      setError(
        `Input of more than 10 shipments is not allowed.`
      );
    } 

    // Add valid tags to the tag list
    if (validTags.length > 0) {
      setTags([...tags, ...validTags]);
    }

    setInputValue("");
  };

  const toggleVisibility = (index) => {
    if (contentRefs.current[index]) {
      // Update visibility state for that specific item
      const updatedVisibility = [...visibility];
      updatedVisibility[index] = !updatedVisibility[index];
      setVisibility(updatedVisibility);

      // Toggle the display of the corresponding ref element
      contentRefs.current[index].style.display = updatedVisibility[index]
        ? "block"
        : "none";
    }
  };
  // Function to remove a tag
  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
    setMtabs(tags.filter((_, index) => index !== indexToRemove));
    setError("");
  };
  const removeTab = (indexToRemove) => {
    setMtabs(mtabs.filter((_, index) => index !== indexToRemove));
    setActiveTabIndex(0);
  };

  const remoaveAllTag = () => {
    setTags([]);
    setMtabs([]);
    setError("");
  };

  var lang = window.localStorage.getItem("lang");
  if (window.localStorage.getItem("lang") == null) {
    lang = "en";
    window.localStorage.setItem("lang", "en");
  }

  const headers = {
    type: "application/json",
    Authorization: `Bearer ` + window.localStorage.getItem("access_token"),
  };
  const buttonRef = useRef();
  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    buttonRef.current.disabled = true;
    setEnquireCommonBUtton('Submitting...');
    if (token != null) {
      if (seletedTags.length > 0) {
        const response = await axios.post(
          Api.userdata.url + "/track_a_shipment_subscription",
          qs.stringify({
            containerids: seletedTags,
            email: data.email,
            subscription_captcha_captcha: token,
          }),
          { headers: headers }
        );
        const res = await response.data;

        if (res["status"] === "OK") {
          swal("Thank you !!!", res["message"], "success").then(() => {
            setShow(false);
          });
        } else {
          swal("Error !", res["message"], "error");
        }
        console.log(data);
      } else {
        swal("Error !", "Please select alteast one BL Number", "error");
      }
    } else {
      swal("Error !", "Invalid captcha", "error");
    }
    setEnquireCommonBUtton('Submit');
    buttonRef.current.disabled = false;
   
    //track_shipment_submit(data);
  };
  const checkcontainer = async (val) => {
    const response = await axios.post(
      Api.userdata.url + "/container_bl_validation",
      qs.stringify({ containerid: val }),
      { headers: headers }
    );
    const res = await response.data;
    if (res == false) {
      return "Enter container number - format : 4 letters followed by 7 digits";
    } else {
      return true;
    }
  };

  const checkContinerNumber = (containerIdToFind) => {
    return trackShipmentRes
      .flatMap((array) => array)
      .find((item) => item.containerid === containerIdToFind);
  };

  const get_track_shipment_submit = async (containeridnew) => {
    const checkShipment = checkContinerNumber(containeridnew);
    console.log(trackShipmentRes);
    if (checkShipment == null) {
      setpdfbuttonstatus(false);
      setloaderFlag("is-active");
      const response = await axios.post(
        Api.userdata.url + "/get_track_shipment_val",
        qs.stringify({
          containerid: containeridnew,
          country_code: country_code,
        }),
        { headers: headers }
      );
      const json = await response.data;

      setSeletedTags([]);

      setLoadedBlNumber([]);
      if (json["status"] == 1 || json["status"] == 0) {
        setoutputflag(true);
        settrackShipmentRes((prevState) => [...prevState, json["message"]]);
        setselectedDataRes(json["message"]);
        console.log(trackShipmentRes);
      } else {
        setoutputflag(false);
        setpdfbuttonstatus(false);
      }
      settrackShipmentflag(true);
      setloaderFlag("is-inactive");
    } else {
      setselectedDataRes(checkShipment);
    }
  };

  const track_shipment_submit = async () => {
    if (tags.length > 0) {
      setMtabs([...tags]);
      get_track_shipment_submit(tags[0]);
      setActiveTabIndex(0);
    } else {
      swal("Error !", "Please enter your BL or Contianer Number", "error");
    }
  };
  const track_shipment_submit_pdf = async (containerid) => {
    setloaderFlag("is-active");
    const response1 = await axios.post(
      Api.userdata.url + "/get_track_shipment_pdf",
      qs.stringify({
        country_code: country_code,
        containerid: containerid,
      }),
      {
        headers: headers,
        responseType: "blob",
      }
    );
    const json1 = await response1.data;
    const file = new Blob([json1], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const tempLink = document.createElement("a");
    tempLink.href = fileURL;
    tempLink.setAttribute("download", containerid + ".pdf");
    tempLink.click();

    setloaderFlag("is-inactive");
  };
  const track_shipment_submit_print = async (containerid) => {
    setloaderFlag("is-active");
    const response1 = await axios.post(
      Api.userdata.url + "/get_track_shipment_pdf",
      qs.stringify({
        country_code: country_code,
        containerid: containerid,
      }),
      {
        headers: headers,
        responseType: "blob",
      }
    );
    const json1 = await response1.data;
    const file = new Blob([json1], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    window.focus();
    setloaderFlag("is-inactive");
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSeletedTags([...seletedTags, value]);
    } else {
      setSeletedTags(seletedTags.filter((item) => item !== value));
    }
  };

  const handleToggle = (val) => {
    setIsRotated(!isRotated);
    setTabAccordianCurrentIndex(val === tabAccordianCurrentIndex ? null : val);
  };

  const reorderTab = (indexVal) => {
    const newItems = [...mtabs];
    const [selectedItem] = newItems.splice(indexVal, 1);
    newItems.unshift(selectedItem);
    setMtabs(newItems);
  };
  return (
    <Layout>
      <div class={"loadernew loader-double " + loaderFlag}></div>
      <section class="inner-center mobile-margin">
        <br></br>
        {/* Modal Component */}
        <Modal
          id="shipmentNofitificationPOPUP"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Set up shipment notifications</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>BL NUMBERS </h5>
            <ul className="shipmentListpopup">
              {trackShipmentRes.map((tag, index) => (

                <>     {tag != null &&
                  tag["container_flag"] == true ? (
                  <> <div className="checkbox">
                    <li> <input
                      type="checkbox"
                      checked={seletedTags.includes(
                        tag['containerid']
                      )}
                      name="select_container[]"
                      value={tag['containerid']}
                      onChange={handleCheckboxChange}
                      className="ts-checkbox ts-checkbox-p"
                    />
                      <span key={index} className="shipmentTagItem">
                        {tag['containerid']}
                      </span>
                    </li>
                  </div> </>) : <></>}
                </>


              ))}
              {tags.length == 0 ? (
                <>
                  <span style={{ color: "red" }}>
                    Please select atleast one BL Number
                  </span>
                </>
              ) : (
                <></>
              )}
            </ul>
            <form
              id="enquire_common"
              method="POST"
              name="enquire_common"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mt-5 mb-3">
                <label>
                  Email<span style={{ color: "red" }}>*</span>
                </label>
                <div className="form-input">
                  <input
                    type="email"
                    className="w-100 emailinput"
                    name="email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Enter your email-id",
                      },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                      maxLength: {
                        value: 100,
                        message: "Your email must be within 100 characters",
                      },
                    })}
                  />
                  {errors.email && (
                    <span class="errors">{errors.email.message}</span>
                  )}
                </div>
                <label className="mt-3">
                  Please check the box below to proceed.
                  <span style={{ color: "red" }}>*</span>
                </label>
                <div className="form-input">
                  <HCaptcha
                    sitekey="a8400b17-0f2c-4116-85cb-91a16f434d49"
                    onVerify={setToken}
                    ref={captchaRef}
                    apihost="https://cn1.hcaptcha.com"
                    endpoint="https://cn1.hcaptcha.com"
                    assethost="https://assets-cn1.hcaptcha.com"
                    imghost="https://imgs-cn1.hcaptcha.com"
                    reportapi="https://reportapi-cn1.hcaptcha.com"
                  />
                </div>
                {errors.hcpactha && (
                  <span class="errors">{errors.message.hcpactha}</span>
                )}
                <div className="mt-2 mb-2">
                  <label className="popupaccept d-flex">
                    <input
                      type="checkbox"
                      className="ts-checkbox"
                      {...register("agreement", {
                        required: {
                          value: true,
                          message: "Please agree the terms & condition",
                        },
                      })}
                    />
                    By clicking "Submit" you agree to Gold Star Line Pvt Ltd
                    <a href="#">Terms of Service</a>
                  </label>
                  {errors.agreement && (
                    <span class="errors">{errors.agreement.message}</span>
                  )}
                </div>
                <div className="d-flex p-0">
                  <button
                    type="submit"
                    className="mt-3  formbuttonSubmit hovereffect"
                    ref={buttonRef}
                  >
                    {enquire_common_button}
                  </button>
                  <button
                    type="button"
                    className="mt-3 ml-md-4 formCancelButton hovereffect"
                    onClick={() => setShow(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <div class="container">
          <div class="row m-0">
            <div
              class="col-lg-12 col-md-12 col-lg-12 no-padd p-0"
              style={{ minHeight: "500px;" }}
            >
              {/* <form
                autocomplete="on"
                name="point-to-point-new"
                //id="point-to-point-new"
                method="get"
                action="#"
                //onSubmit={handleSubmit(onSubmit)}
              > */}
              <div class="form-fback p-0 pt-5 ">
                <div class="row dout-inner m-0">
                  <div class="col-lg-5 col-md-12 col-sm-12 pot-form p-0">
                    <div class="">
                      <h3>{track_shipment_lang[lang].TrackaShipment}</h3>
                      <p>{track_shipment_lang[lang].TrackaShipment1}</p>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 p-0 pot-form siv-multi-shipment">
                    <div class="row">
                      <div class="col-md-10 col-lg-10 col-sm-12">
                        <label>
                          Please enter up to 10 reference numbers separated by a
                          comma, space or enter.
                        </label>
                        <div className="tagsTool">
                          <div className="tags-list">
                            {tags.map((tag, index) => (
                              <span key={index} className="tagInfo">
                                {tag}
                                <button
                                  onClick={() => removeTag(index,tag)}
                                  style={{ marginLeft: "5px" }}
                                >
                                  x
                                </button>
                              </span>
                            ))}
                          </div>
                          <div className="inputBox">
                            <input
                              type="text"
                              id="containerid"
                              name="containerid"
                              class="form-control"
                              style={{ textTransform: "uppercase" }}
                              value={inputValue}
                              onChange={handleInputChange}
                              onBlur={handleClick}
                              onKeyDown={handleKeyDown}
                            />

                            <button
                              className="clearAll"
                              onClick={() => remoaveAllTag()}
                              style={{ marginLeft: "5px" }}
                            >
                              <span>Clear All X</span>
                            </button>
                          </div>
                        </div>
                        {error && <p class="errors">{error}</p>}
                      </div>
                      <div class="col-md-2 col-lg-2 col-sm-12 track-cont-no">
                        <button
                          class="btn btn-default hovereffect portal-searc-tariff"
                          id="submitDetails"
                          name="my-buttons"
                          type="button"
                          onClick={track_shipment_submit}
                          ref={submitForm}
                        >
                          {track_shipment_lang[lang].FindContainer}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  </form> */}
            </div>
          </div>
        </div>

        {mtabs.length > 0 ? (
          <section className="mt-5">
            <div className="container">


              <ul
                class="nav nav-tabs trade-tab-li d-none d-md-flex position-relative"
                role="tablist"
                id="multishippingTabs"
              >
                {mtabs.length > 0 &&
                  mtabs.map((val, i) => {
                    if (i <= 3) {
                      return (
                        <li role="presentation" className="nav-item" key={i}>
                          <a
                            href="javascript: void(0)"
                            onClick={(e) => {
                              setActiveTabIndex(i);
                              get_track_shipment_submit(val);
                              setTabAccordianCurrentIndex(0)
                            }}
                            className={`d-flex justify-content-center align-items-center nav-link ${activeTabIndex === i ? "active" : ""
                              }`}
                          >
                            <span class="mr-2 mt-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <rect
                                  x="1.25"
                                  y="5.25"
                                  width="21.5"
                                  height="13.5"
                                  rx="1.75"
                                  stroke={`${activeTabIndex === i ? "white" : "black"
                                    }`}
                                  strokeWidth="1.5"
                                ></rect>
                                <rect
                                  x="5"
                                  y="8"
                                  width="2"
                                  height="8.44"
                                  rx="1"
                                  fill={`${activeTabIndex === i ? "white" : "black"
                                    }`}
                                ></rect>
                                <rect
                                  x="9"
                                  y="8"
                                  width="2"
                                  height="8.44"
                                  rx="1"
                                  fill={`${activeTabIndex === i ? "white" : "black"
                                    }`}
                                ></rect>
                                <rect
                                  x="13"
                                  y="8"
                                  width="2"
                                  height="8.44"
                                  rx="1"
                                  fill={`${activeTabIndex === i ? "white" : "black"
                                    }`}
                                ></rect>
                                <rect
                                  x="17"
                                  y="8"
                                  width="2"
                                  height="8.44"
                                  rx="1"
                                  fill={`${activeTabIndex === i ? "white" : "black"
                                    }`}
                                ></rect>
                              </svg>
                            </span>
                            {val}

                            <span
                              onClick={(e) => {
                                var test = i;
                                /*setActiveTabIndex(test-1);*/
                                e.preventDefault(); // Prevent default behavior of the <a> tag
                                e.stopPropagation();
                                removeTab(i);
                              }}
                              className="ml-2 d-inline-block"
                            >
                              X
                            </span>
                          </a>
                        </li>
                      );
                    }
                  })}
                {mtabs.length > 4 ? (
                  <li
                    role="presentation"
                    className="nav-item position-absolute"
                    style={{ right: 0 }}
                  >
                    <a
                      className="d-flex justify-content-center align-items-center nav-link "
                      href="javascript:void(0)"
                    >
                      <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                          More
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {mtabs.length > 4 &&
                            mtabs.map((val, i) => {
                              if (i >= 4) {
                                return (
                                  <Dropdown.Item
                                    href="javascript:void(0);"
                                    onClick={(e) => {
                                      reorderTab(i);
                                      get_track_shipment_submit(val);
                                      setActiveTabIndex(0);
                                    }}
                                  >
                                    {val}
                                  </Dropdown.Item>
                                );
                              }
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </a>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
              <div className="mobMulitDropdown d-flex d-md-none ">
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic-mobile">
                    {mtabs && mtabs[activeTabIndex]}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="postion-relative">
                    {mtabs.length > 0 &&
                      mtabs.map((val, i) => {
                        return (
                          <div className="d-flex justify-content-around">
                            <Dropdown.Item
                             onClick={(e) => {
                              reorderTab(i);
                              get_track_shipment_submit(val);
                              setActiveTabIndex(0);
                            }}
                              href="javascript:void(0);"
                          
                            >
                              <span class="mr-2 mt-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <rect
                                    x="1.25"
                                    y="5.25"
                                    width="21.5"
                                    height="13.5"
                                    rx="1.75"
                                    stroke="black"
                                    strokeWidth="1.5"
                                  ></rect>
                                  <rect
                                    x="5"
                                    y="8"
                                    width="2"
                                    height="8.44"
                                    rx="1"
                                    fill="black"
                                  ></rect>
                                  <rect
                                    x="9"
                                    y="8"
                                    width="2"
                                    height="8.44"
                                    rx="1"
                                    fill="black"
                                  ></rect>
                                  <rect
                                    x="13"
                                    y="8"
                                    width="2"
                                    height="8.44"
                                    rx="1"
                                    fill="black"
                                  ></rect>
                                  <rect
                                    x="17"
                                    y="8"
                                    width="2"
                                    height="8.44"
                                    rx="1"
                                    fill="black"
                                  ></rect>
                                </svg>
                              </span>{" "}
                              {val}
                            </Dropdown.Item>
                            <span
                              onClick={(e) => removeTab(i)}
                              className="ml-2 d-inline-block z-n1 position-absolute delBtn"
                            >
                              X
                            </span>
                          </div>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <hr />
              <div class="tab-content  booking-tab-cont">
                {mtabs.length > 0 &&
                  mtabs.map((val, i) => {
                    return (
                      <div
                        className={`tab-pane ${activeTabIndex === i ? "active" : ""
                          }`}
                        role="tabpanel"
                        id="step1"
                      >

                        <div class="container" >
                          <div class="col-md-12  p-0">
                            <div
                              class="row"
                              style={{
                                display: (selectedDataRes != null &&
                                  selectedDataRes["container_flag"] == true) ? `` : `none`,
                              }}
                            >
                              <div className="col-md-10 form-fback notifcationBar d-flex"></div>
                             {/*  <div className="col-md-10 form-fback notifcationBar d-flex">
                                {

                                }
                                <a
                                  href="javascript:void(0);"

                                  onClick={() => {
                                    setSeletedTags([selectedDataRes["containerid"]]);
                                    handleShow();
                                  }}
                                  className="notificationBtn"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={22}
                                    height={24}
                                    fill="none"
                                  >
                                    <path
                                      fill="#666"
                                      d="M11.454 0c.834 0 1.507.67 1.507 1.5v.9c3.437.694 6.026 3.722 6.026 7.35v.881a9 9 0 0 0 2.283 5.982l.349.389a1.5 1.5 0 0 1-1.126 2.498H2.415a1.51 1.51 0 0 1-1.374-.886 1.491 1.491 0 0 1 .25-1.612l.348-.39a8.99 8.99 0 0 0 2.283-5.98V9.75c0-3.628 2.59-6.656 6.026-7.35v-.9c0-.83.673-1.5 1.506-1.5ZM9.322 23.123A2.994 2.994 0 0 1 8.442 21h6.025c0 .797-.315 1.56-.88 2.123a3.021 3.021 0 0 1-2.133.877 3.02 3.02 0 0 1-2.132-.877Z"
                                    />
                                  </svg>
                                  Notify me about all shipments
                                </a>
                              </div> */}
                              <div class=" pr-2 sch-full-form-btn ">
                               {/*  <p class="tariff-print" style={{ padding: `10px` }}>
                                  <a
                                    onClick={() => track_shipment_submit_pdf(selectedDataRes["containerid"])}
                                    class="btn btn-info btn-lg"
                                  >
                                    <span class="glyphicon glyphicon-print"></span> {track_shipment_lang[lang].PDF}
                                  </a>
                                </p> */}
                              </div>
                              <div class=" pr-2 sch-full-form-btn ">
                                <p class="tariff-print" style={{ padding: `10px` }}>
                                  <a
                                    onClick={() => track_shipment_submit_print(selectedDataRes["containerid"])}
                                    class="btn btn-info btn-lg"
                                  >
                                    <span class="glyphicon glyphicon-print"></span> {track_shipment_lang[lang].Print}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="trackShipmentResultCard">
                          <div className="col-md-12 no-padd">
                            {selectedDataRes != null &&
                              selectedDataRes["container_flag"] == true ? (
                              <>
                                <div className="trackShipmentResultRow">
                                  <div className="trackShipmentResultRowInner">
                                    {/*  <div className="checkbox">
                                              <input
                                                type="checkbox"
                                                checked={seletedTags.includes(
                                                  trackShipmentRes["containerid"]
                                                )}
                                                name="select_container[]"
                                                value={trackShipmentRes["containerid"]}
                                                onChange={handleCheckboxChange}
                                                className="ts-checkbox"
                                              />
                                            </div> */}
                                    <div className="cartItem">
                                      <label>BL NUMBER</label>
                                      <h3>{selectedDataRes["containerid"]}</h3>
                                    </div>
                                    <div className="cartItem">
                                      <label>Port of Loading (POL)</label>
                                      <h3>
                                        {
                                          selectedDataRes["pol"][
                                          "port_of_loading"
                                          ]
                                        }
                                      </h3>
                                    </div>
                                    {/*  <div className="cartItem">
                                    <label>Terminal Name</label>
                                    <h3>N/A</h3>
                                  </div> */}
                                    <div className="cartItem">
                                      <label>Sailing Date</label>
                                      <h3>
                                        {selectedDataRes["pol"]["sailingdate"]}
                                      </h3>
                                    </div>
                                    <div className="cartItem">
                                      <label>Port of Discharge (POD)</label>
                                      <h3>
                                        {
                                          selectedDataRes["pod"][
                                          "port_of_discharge"
                                          ]
                                        }
                                      </h3>
                                    </div>
                                    {/*  <div className="cartItem">
                                    <label>Terminal Name</label>
                                    <h3>N/A</h3>
                                  </div> */}
                                    <div className="cartItem">
                                      <label>ETD</label>
                                      <h3>
                                        {
                                          selectedDataRes["pod"][
                                          "estimated_time_of_arrival"
                                          ]
                                        }
                                      </h3>
                                    </div>
                                    <div className=" ">
                                      <div
                                        class="arrowButton"
                                        style={{
                                          transform: "rotate(90deg)",
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M9 18L15 12L9 6"
                                            stroke="white"
                                            strokeWidth="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          ></path>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  <div
                                    ref={(el) => (contentRefs.current[i] = el)}
                                    style={{
                                      display: "block",
                                    }}
                                    className="trackShipmentResultRowInnerResult"
                                  >
                                    <>
                                      <div>
                                        {selectedDataRes["container_flag"] ==
                                          true ? (
                                          <div class="row track-shipment-output">
                                            <table class="table track-shipment-output-tb1">
                                              <thead>
                                                <th>
                                                  <h3 class="track-shipment-heading">
                                                    <span>
                                                      {
                                                        track_shipment_lang[
                                                          lang
                                                        ].BLNo
                                                      }
                                                    </span>
                                                    {
                                                      selectedDataRes[
                                                      "containerid"
                                                      ]
                                                    }
                                                  </h3>
                                                </th>
                                              </thead>
                                            </table>
                                            <div class="col-md-6 col-lg-6 col-sm-12 p-1">
                                              <div class="card p-3 main-cargo-head-container">
                                                <table class="table table1">
                                                  <tbody>
                                                    {selectedDataRes["pol"][
                                                      "port_of_receipt"
                                                    ] != "" ? (
                                                      <tr>
                                                        <td class="table-font-weight">
                                                          {
                                                            track_shipment_lang[
                                                              lang
                                                            ].PortofReceipt
                                                          }
                                                        </td>

                                                        <td>
                                                          {
                                                            selectedDataRes[
                                                            "pol"
                                                            ]["port_of_receipt"]
                                                          }
                                                        </td>
                                                      </tr>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    <tr>
                                                      <td class="table-font-weight">
                                                        {
                                                          track_shipment_lang[
                                                            lang
                                                          ].PortofLoading
                                                        }
                                                      </td>
                                                      <br></br>
                                                      <td className="font-bold pt-0">
                                                        {
                                                          selectedDataRes[
                                                          "pol"
                                                          ]["port_of_loading"]
                                                        }
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td class="table-font-weight">
                                                        {
                                                          track_shipment_lang[
                                                            lang
                                                          ].TerminalName
                                                        }
                                                      </td>
                                                      <td>
                                                        {
                                                          selectedDataRes[
                                                          "pol"
                                                          ]["terminal_name"]
                                                        }
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td class="table-font-weight">
                                                        {
                                                          track_shipment_lang[
                                                            lang
                                                          ].SailingDate
                                                        }
                                                      </td>
                                                      <td>
                                                        {moment(
                                                          selectedDataRes[
                                                          "pol"
                                                          ]["sailingdate"]
                                                        ).format("DD-MMM-YYYY")}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 p-1 ">
                                              <div class="card p-3 main-cargo-head-container">
                                                <table class="table table1">
                                                  <tbody>
                                                    <tr>
                                                      <td class="table-font-weight">
                                                        {
                                                          track_shipment_lang[
                                                            lang
                                                          ].PortofDischarge
                                                        }
                                                      </td>
                                                      <br></br>
                                                      <td className="font-bold pt-0">
                                                        {
                                                          selectedDataRes[
                                                          "pod"
                                                          ]["port_of_discharge"]
                                                        }
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td class="table-font-weight">
                                                        {
                                                          track_shipment_lang[
                                                            lang
                                                          ].FinalDestination
                                                        }
                                                        :
                                                      </td>
                                                      <td>
                                                        {
                                                          selectedDataRes[
                                                          "pod"
                                                          ]["final_destination"]
                                                        }
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td class="table-font-weight">
                                                        {
                                                          track_shipment_lang[
                                                            lang
                                                          ]
                                                            .EstimatedTimeofArrival
                                                        }
                                                      </td>
                                                      <td>
                                                        {
                                                          selectedDataRes[
                                                          "pod"
                                                          ][
                                                          "estimated_time_of_arrival"
                                                          ]
                                                        }
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                            {typeof selectedDataRes["response"][
                                              "blRouteLegs"
                                            ] !== "undefined" &&
                                              selectedDataRes["response"][
                                                "blRouteLegs"
                                              ].map((blRouteLegsss, i) => {
                                                {
                                                  {
                                                    var last_leg = true;
                                                    if (
                                                      selectedDataRes[
                                                        "response"
                                                      ]["blRouteLegs"].length -
                                                      1 ==
                                                      i
                                                    ) {
                                                      if (
                                                        blRouteLegsss[
                                                        "arrivalDateTz"
                                                        ]["dateTime"] != null &&
                                                        blRouteLegsss[
                                                        "portToType"
                                                        ] == "DEL"
                                                      ) {
                                                        last_leg = true;
                                                      } else {
                                                        last_leg = false;
                                                      }
                                                    }

                                                    var ETA = "";

                                                    if (i != 0) {
                                                      var arrivalDateTz =
                                                        selectedDataRes[
                                                        "response"
                                                        ]["blRouteLegs"][i - 1][
                                                        "arrivalDateTz"
                                                        ]["dateTime"];
                                                      if (
                                                        arrivalDateTz != null
                                                      ) {
                                                        ETA =
                                                          moment(
                                                            arrivalDateTz
                                                          ).format(
                                                            "DD-MMM-YYYY"
                                                          );
                                                        /* 	var date = date_create($arrivalDateTz);
                                     ETA = date_format($date,"d-M-Y"); */
                                                      }
                                                    }
                                                  }
                                                  return (
                                                    <>
                                                      <div class="table-responsive p-1">
                                                        <table
                                                          class="table"
                                                          style={{
                                                            background: `#f3f3f3`,
                                                            color: `#123c60`,
                                                          }}
                                                        >
                                                          <tbody>
                                                            <tr
                                                              style={{
                                                                background:
                                                                  last_leg ==
                                                                    true
                                                                    ? ``
                                                                    : `#ffe787`,
                                                              }}
                                                            >
                                                              <td width="25%">
                                                                <p
                                                                  class="table-font-weight"
                                                                  style={{
                                                                    paddingRight: `20px`,
                                                                  }}
                                                                >
                                                                  {typeof selectedDataRes[
                                                                    "array"
                                                                  ][
                                                                    blRouteLegsss[
                                                                    "portFromType"
                                                                    ]
                                                                  ] !==
                                                                    "undefined"
                                                                    ? selectedDataRes[
                                                                    "array"
                                                                    ][
                                                                    blRouteLegsss[
                                                                    "portFromType"
                                                                    ]
                                                                    ]
                                                                    : blRouteLegsss[
                                                                    "portFromType"
                                                                    ]}
                                                                </p>
                                                                <p>
                                                                  {blRouteLegsss[
                                                                    "portNameFrom"
                                                                  ] +
                                                                    ", " +
                                                                    blRouteLegsss[
                                                                    "countryNameFrom"
                                                                    ]}
                                                                </p>
                                                              </td>
                                                              {blRouteLegsss[
                                                                "voyage"
                                                              ] != null ? (
                                                                <td
                                                                  width="25%"
                                                                  style={{
                                                                    background: `#ffe2d1`,
                                                                  }}
                                                                >
                                                                  <p
                                                                    class="table-font-weight"
                                                                    style={{
                                                                      paddingRight: `20px`,
                                                                    }}
                                                                  >
                                                                    {
                                                                      track_shipment_lang[
                                                                        lang
                                                                      ]
                                                                        .VesselVoyage
                                                                    }
                                                                  </p>
                                                                  <p>
                                                                    {
                                                                      blRouteLegsss[
                                                                      "vesselName"
                                                                      ]
                                                                    }
                                                                    /
                                                                    {blRouteLegsss[
                                                                      "voyage"
                                                                    ] +
                                                                      " (" +
                                                                      blRouteLegsss[
                                                                      "vessel"
                                                                      ] +
                                                                      ")"}
                                                                  </p>
                                                                </td>
                                                              ) : last_leg ? (
                                                                <td
                                                                  width="25%"
                                                                  style={{
                                                                    background: `#ffe2d1`,
                                                                  }}
                                                                >
                                                                  <p
                                                                    class="table-font-weight"
                                                                    style={{
                                                                      paddingRight: `20px`,
                                                                    }}
                                                                  >
                                                                    {
                                                                      track_shipment_lang[
                                                                        lang
                                                                      ]
                                                                        .TruckRail
                                                                    }
                                                                  </p>
                                                                  <p></p>
                                                                </td>
                                                              ) : (
                                                                <td
                                                                  width="25%"
                                                                  className="mob-width"
                                                                ></td>
                                                              )}
                                                              <td width="25%">
                                                                {ETA != "" ? (
                                                                  <>
                                                                    <p
                                                                      class="table-font-weight"
                                                                      style={{
                                                                        paddingRight: `20px`,
                                                                      }}
                                                                    >
                                                                      {
                                                                        track_shipment_lang[
                                                                          lang
                                                                        ].ETA
                                                                      }
                                                                    </p>
                                                                    <p>{ETA}</p>
                                                                  </>
                                                                ) : (
                                                                  <></>
                                                                )}
                                                              </td>
                                                              <td
                                                                width="25%"
                                                                className="mob-width"
                                                              >
                                                                {typeof blRouteLegsss[
                                                                  "sailingDateTz"
                                                                ][
                                                                  "dateTime"
                                                                ] !==
                                                                  "undefined" &&
                                                                  blRouteLegsss[
                                                                  "sailingDateTz"
                                                                  ]["dateTime"] !=
                                                                  null ? (
                                                                  <>
                                                                    <p
                                                                      class="table-font-weight"
                                                                      style={{
                                                                        paddingRight: `20px`,
                                                                      }}
                                                                    >
                                                                      {
                                                                        track_shipment_lang[
                                                                          lang
                                                                        ].ETD
                                                                      }
                                                                    </p>
                                                                    <p>
                                                                      {moment(
                                                                        blRouteLegsss[
                                                                        "sailingDateTz"
                                                                        ][
                                                                        "dateTime"
                                                                        ]
                                                                      ).format(
                                                                        "DD-MMM-YYYY"
                                                                      )}
                                                                    </p>
                                                                  </>
                                                                ) : (
                                                                  <></>
                                                                )}
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                      {selectedDataRes[
                                                        "response"
                                                      ]["blRouteLegs"].length !=
                                                        i + 1 ? (
                                                        <div class="col-12 text-center">
                                                          <img
                                                            src={downarrow}
                                                            style={{
                                                              width: `25px`,
                                                              marginBottom:
                                                                "15px",
                                                            }}
                                                          />
                                                        </div>
                                                      ) : (
                                                        <></>
                                                      )}
                                                      {blRouteLegsss[
                                                        "arrivalDateTz"
                                                      ]["dateTime"] != null &&
                                                        blRouteLegsss[
                                                        "portToType"
                                                        ] == "DEL" ? (
                                                        <>
                                                          <div class="col-12 text-center">
                                                            <img
                                                              src={downarrow}
                                                              style={{
                                                                width: `25px`,
                                                              }}
                                                            />
                                                          </div>
                                                          <div class="table-responsive p-1">
                                                            <table
                                                              class="table"
                                                              style={{
                                                                background: `#f3f3f3`,
                                                                color: `#123c60`,
                                                              }}
                                                            >
                                                              <tbody>
                                                                <tr
                                                                  style={{
                                                                    background: `#ffe787`,
                                                                    marginTop:
                                                                      "20px",
                                                                  }}
                                                                >
                                                                  <td width="25%">
                                                                    <p
                                                                      class="table-font-weight"
                                                                      style={{
                                                                        paddingRight: `20px`,
                                                                      }}
                                                                    >
                                                                      {typeof selectedDataRes[
                                                                        "array"
                                                                      ][
                                                                        blRouteLegsss[
                                                                        "portToType"
                                                                        ]
                                                                      ] !==
                                                                        "undefined"
                                                                        ? selectedDataRes[
                                                                        "array"
                                                                        ][
                                                                        blRouteLegsss[
                                                                        "portToType"
                                                                        ]
                                                                        ]
                                                                        : blRouteLegsss[
                                                                        "portToType"
                                                                        ]}
                                                                    </p>

                                                                    <p>
                                                                      {blRouteLegsss[
                                                                        "portNameTo"
                                                                      ] +
                                                                        ", " +
                                                                        blRouteLegsss[
                                                                        "countryNameTo"
                                                                        ]}
                                                                    </p>
                                                                  </td>
                                                                  <td width="25%"></td>
                                                                  <td width="25%">
                                                                    <p
                                                                      class="table-font-weight"
                                                                      style={{
                                                                        paddingRight: `20px;`,
                                                                      }}
                                                                    >
                                                                      {
                                                                        track_shipment_lang[
                                                                          lang
                                                                        ].ETA
                                                                      }
                                                                    </p>
                                                                    <p>
                                                                      {moment(
                                                                        blRouteLegsss[
                                                                        "arrivalDateTz"
                                                                        ][
                                                                        "dateTime"
                                                                        ]
                                                                      ).format(
                                                                        "DD-MMM-YYYY"
                                                                      )}
                                                                    </p>
                                                                  </td>
                                                                  <td width="25%"></td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  );
                                                }
                                              })}
                                          </div>
                                        ) : (
                                          <div class="row track-shipment-output">
                                            <table class="table track-shipment-output-tb1">
                                              <thead>
                                                <th>
                                                  <h3 class="track-shipment-heading">
                                                    <span>
                                                      {
                                                        track_shipment_lang[
                                                          lang
                                                        ].ContainerNo
                                                      }
                                                    </span>
                                                    {
                                                      selectedDataRes[
                                                      "containerid"
                                                      ]
                                                    }
                                                  </h3>
                                                </th>
                                              </thead>
                                            </table>
                                          </div>
                                        )}
                                      </div>
                                      <div className="custom-card-container track_shipment_inner ">
                                        <Accordion defaultActiveKey="0">
                                          {typeof selectedDataRes["result"] !==
                                            "undefined" &&
                                            selectedDataRes["result"].map(
                                              (results, i) => {
                                                {
                                                }
                                                return (
                                                  <>
                                                    <Card>
                                                      <Card.Header>
                                                        <div className="card-grid-container">
                                                          <div className="grid-item">
                                                            <div className="card-tittle">
                                                              {
                                                                track_shipment_lang[
                                                                  lang
                                                                ].Container
                                                              }
                                                            </div>
                                                            <div className="card-data">
                                                              {
                                                                results[
                                                                "container"
                                                                ]
                                                              }
                                                            </div>
                                                          </div>
                                                          <div className="grid-item">
                                                            <div className="card-tittle">
                                                              {
                                                                track_shipment_lang[
                                                                  lang
                                                                ].LastActivity
                                                              }
                                                            </div>
                                                            <div className="card-data">
                                                              {
                                                                results[
                                                                "last_activity"
                                                                ]
                                                              }
                                                            </div>
                                                          </div>
                                                          <div className="grid-item">
                                                            <div className="card-tittle">
                                                              {
                                                                track_shipment_lang[
                                                                  lang
                                                                ].Location
                                                              }
                                                            </div>
                                                            <div className="card-data">
                                                              {results[
                                                                "placeFromDesc"
                                                              ] != null
                                                                ? results[
                                                                "placeFromDesc"
                                                                ]
                                                                : "" +
                                                                  ", " +
                                                                  (results[
                                                                    "location"
                                                                  ] !=
                                                                    null)
                                                                  ? results[
                                                                  "location"
                                                                  ]
                                                                  : ""}
                                                            </div>
                                                          </div>
                                                          <div className="grid-item">
                                                            <div className="card-tittle">
                                                              {
                                                                track_shipment_lang[
                                                                  lang
                                                                ].Date
                                                              }
                                                            </div>
                                                            <div className="card-data">
                                                              {results[
                                                                "date"
                                                              ] != null
                                                                ? moment(
                                                                  results[
                                                                  "date"
                                                                  ]
                                                                ).format(
                                                                  "DD-MMM-YYYY"
                                                                )
                                                                : ""}
                                                            </div>
                                                          </div>
                                                          <div className="grid-item">
                                                            <div className="card-tittle">
                                                              {
                                                                track_shipment_lang[
                                                                  lang
                                                                ].VesselVoyage
                                                              }
                                                            </div>
                                                            <div className="card-data"></div>
                                                          </div>
                                                        </div>

                                                        <Accordion.Toggle
                                                          className="custom-toggle-link"
                                                          eventKey={i.toString()}
                                                          onClick={(e) => handleToggle(i)}
                                                        >
                                                          {/*   <img
                                                          src={big_arrow}
                                                          alt="Arrow"
                                                          className={`arrow-icon ${isRotated ? "rotate" : ""}`}
                                                        /> */}

                                                          <div
                                                            class={`arrowButton arrow-icon ${tabAccordianCurrentIndex === i
                                                              ? "rotate"
                                                              : ""
                                                              }`}
                                                          >
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="24"
                                                              height="24"
                                                              viewBox="0 0 24 24"
                                                              fill="none"
                                                            >
                                                              <path
                                                                d="M9 18L15 12L9 6"
                                                                stroke="white"
                                                                strokeWidth="2"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                              ></path>
                                                            </svg>
                                                          </div>
                                                        </Accordion.Toggle>
                                                      </Card.Header>
                                                      <Accordion.Collapse eventKey={i.toString()}>
                                                        <Card.Body>
                                                          <div className="track-shipment">
                                                            {typeof results[
                                                              "new_rec"
                                                            ] !== "undefined" &&
                                                              results["new_rec"]
                                                                .reverse()
                                                                .map(
                                                                  (
                                                                    new_rec,
                                                                    j
                                                                  ) => {
                                                                    {
                                                                    }
                                                                    return (
                                                                      <>
                                                                        <div class="grid-container">
                                                                          <div class="grid-item">
                                                                            <div class="grid-header"></div>
                                                                          </div>

                                                                          <div class="grid-item">
                                                                            <div class="grid-header">
                                                                              {
                                                                                track_shipment_lang[
                                                                                  lang
                                                                                ]
                                                                                  .LastActivity
                                                                              }
                                                                            </div>
                                                                            {
                                                                              new_rec[
                                                                              "activityDesc"
                                                                              ]
                                                                            }
                                                                          </div>
                                                                          <div class="grid-item">
                                                                            <div class="grid-header">
                                                                              {
                                                                                track_shipment_lang[
                                                                                  lang
                                                                                ]
                                                                                  .Location
                                                                              }
                                                                            </div>
                                                                            {new_rec[
                                                                              "placeFromDesc"
                                                                            ] +
                                                                              ", " +
                                                                              new_rec[
                                                                              "countryFromName"
                                                                              ]}
                                                                          </div>
                                                                          <div class="grid-item">
                                                                            <div class="grid-header">
                                                                              {
                                                                                track_shipment_lang[
                                                                                  lang
                                                                                ]
                                                                                  .Date
                                                                              }
                                                                            </div>

                                                                            {moment(
                                                                              new_rec[
                                                                              "activityDateTz"
                                                                              ]
                                                                            ).format(
                                                                              "DD-MMM-YYYY"
                                                                            )}
                                                                          </div>
                                                                          <div class="grid-item">
                                                                            <div class="grid-header">
                                                                              {
                                                                                track_shipment_lang[
                                                                                  lang
                                                                                ]
                                                                                  .VesselVoyage
                                                                              }
                                                                            </div>
                                                                            {new_rec[
                                                                              "voyage"
                                                                            ] !=
                                                                              " / "
                                                                              ? new_rec[
                                                                              "voyage"
                                                                              ]
                                                                              : ""}
                                                                          </div>
                                                                        </div>
                                                                      </>
                                                                    );
                                                                  }
                                                                )}
                                                          </div>
                                                        </Card.Body>
                                                      </Accordion.Collapse>
                                                    </Card>
                                                  </>
                                                );
                                              }
                                            )}
                                        </Accordion>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div
                              className="row"
                              style={{
                                minHeight: "200px",
                                border: "solid 1px",
                                display: trackShipmentflag ? "block" : "none",
                                justifyContent: "center",
                                alignItems: "center", // Aligns content vertically in the center
                                textAlign: "center",  // Aligns text horizontally in the center
                              }}
                            >
                              <div className="col">
                                <h3 style={{ paddingTop: "72px" }}>
                                  <i className="fa fa-search" aria-hidden="true"></i>
                                  {track_shipment_lang[lang].NoRecordFound}.
                                </h3>
                              </div>
                            </div>
                            )}
                          </div>
                          <div class="container">
                            <div class="col-md-12  p-2">
                              <div
                                class="row"
                                style={{
                                  display: pdfbuttonstatus ? `` : `none`,
                                }}
                              >
                                <div class="pb-2 pr-2 sch-full-form-btn ">
                                  <p
                                    class="tariff-print"
                                    style={{ padding: `10px` }}
                                  >
                                    <a
                                      onClick={track_shipment_submit_pdf}
                                      class="btn btn-info btn-lg"
                                    >
                                      <span class="glyphicon glyphicon-print"></span>
                                      {track_shipment_lang[lang].PDF}
                                    </a>
                                  </p>
                                </div>
                                <div class="pb-2 pr-2 sch-full-form-btn ">
                                  <p
                                    class="tariff-print"
                                    style={{ padding: `10px` }}
                                  >
                                    <a
                                      onClick={track_shipment_submit_print}
                                      class="btn btn-info btn-lg"
                                    >
                                      <span class="glyphicon glyphicon-print"></span>
                                      {track_shipment_lang[lang].Print}
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </section>
        ) : (
          <></>
        )}
        <section>
          <div className="container">
            <div className="row trackshipments_results"></div>
          </div>
        </section>
      </section>

      <GET_IN_TOUCH />
    </Layout>
  );
};
export default Track_shipments;
